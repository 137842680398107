import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Template({ data }) {
  const activity = data.markdownRemark;
  const { title, description, featuredAlt } = activity.frontmatter;

  return (
      <Layout>
          <Seo title={ title } />
          <Seo image={ getSrc(activity.frontmatter.featuredImage) } />

          <section className="bg-primary text-center main" id="main">
            <div className="container">

                <div className="activity">
                    <h2 className="text-uppercase mb-0">{ title }</h2>
                    <hr className="star-dark pad-bottom" />
                    <div className="featured-image">
                        <GatsbyImage image={getImage(activity.frontmatter.featuredImage)}
                             alt={ featuredAlt } />
                    </div>

                    <div className="description">
                        <p>{ description }</p>

                        <div dangerouslySetInnerHTML={{ __html: activity.html }} />
                    </div>
                </div>

                <hr className="star-dark pad-bottom" />

                    <h3>Like what you see?</h3>
                    <p>Show your appreciation!</p>
                    <p><a href="mailto:kate@currie.com.au?subject=Ten Minutes for Mama"><i className="fa fa-envelope"></i> send me feedback</a></p>
                    <p><a target="_blank" rel="noreferrer" href="https://www.buymeacoffee.com/tenminutes"><i className="fa fa-coffee"></i> buy me a coffee</a></p>

                <hr className="star-dark pad-bottom" />

                <form className="another-ten" action="/activities">
                    <p><button className="btn btn-secondary gimmeTen"
                               type="submit">Get another ten minutes</button></p>
                </form>
            </div>
        </section>
    </Layout>
    )
};

export const postQuery = graphql`
    query activity($path: String!) {
      markdownRemark(frontmatter: {path: {eq: $path}}) {
        frontmatter {
          activityId
          author
          date
          description
          featuredAlt
          path
          title
          featuredImage {
            childImageSharp {
             gatsbyImageData(
               width: 600
               placeholder: BLURRED
               formats: [AUTO, WEBP, AVIF]
             )
            }
          }
        }
        html
      }
    }
`;